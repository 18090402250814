import React, { useMemo, useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import ListDropdown from "../../components/lists/dropdown";
import { useTheme } from "@mui/styles";
import CardLink from "../../components/cards/card-link";
import { GatsbyImage } from "gatsby-plugin-image";
import CustomButtonText from "../../components/button/custom-button";
import ListOfCards from "../../components/cards/list-of-cards";
import { useI18next } from "gatsby-plugin-react-i18next";
import { getAlternateLang } from "../../utils/alternate-lang";
import BrickNewsletter from "../../components/bricks/newsletter";
import { gtmPushUserInfo } from "../../services/auth";

export const query = graphql`
  fragment StrapiNewsletterLiveEventsFragment on StrapiNewsletterLiveEvents {
    Newsletter {
      title
      subtitle
      background {
        alternativeText
      }
    }
    backgroundImage {
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
  query LiveEvents($language: String!) {
    liveEventsEn: allStrapiLiveEvent(
      filter: { locale: { eq: "en" }, date: { ne: null } }
      sort: { order: DESC, fields: [date] }
    ) {
      edges {
        node {
          Slug
          strapiId
          Title
          date(formatString: "MMMM DD, YYYY")
          description {
            richText
          }
          location
          thumbnail {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 223)
              }
            }
          }
          therapeutical_area {
            Slug
            Name
            color
          }
        }
      }
    }
    liveEventsEs: allStrapiLiveEvent(
      filter: { locale: { eq: "es-PA" }, date: { ne: null } }
      sort: { order: DESC, fields: [date] }
    ) {
      edges {
        node {
          id
          Slug
          strapiId
          Title
          date(formatString: "MMMM DD, YYYY")
          description {
            richText
          }
          location
          thumbnail {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 223)
              }
            }
          }
          therapeutical_area {
            Slug
            Name
            color
          }
        }
      }
    }
    strapiNewsletterLiveEventsEn: strapiNewsletterLiveEvents(
      locale: { eq: "en" }
    ) {
      ...StrapiNewsletterLiveEventsFragment
    }
    strapiNewsletterLiveEventsEs: strapiNewsletterLiveEvents(
      locale: { eq: "es-PA" }
    ) {
      ...StrapiNewsletterLiveEventsFragment
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const LiveEvents = ({ data }) => {
  const { t, language } = useI18next();
  const theme = useTheme();
  const liveEvents =
    language === "en" ? data.liveEventsEn.edges : data.liveEventsEs.edges;
  const newsletter =
    language === "en"
      ? data.strapiNewsletterLiveEventsEn
      : data.strapiNewsletterLiveEventsEs;
  const [activeSlugs, setActiveSlugs] = useState([]);

  const [seeHowMany, setSeeHowMany] = useState(3);

  const isFiltered = useMemo(() => {
    return activeSlugs.length > 0;
  }, [activeSlugs]);

  const filteredLiveEvents = useMemo(() => {
    return activeSlugs.length > 0
      ? liveEvents.filter((liveEvent) =>
          activeSlugs.includes(liveEvent.node.therapeutical_area.Name)
        )
      : liveEvents;
  }, [activeSlugs, liveEvents]);

  const seo = {
    metaTitle: t("metaData.events.metaTitle"),
    metaDescription: t("metaData.events.metaDescription"),
    linkAlternateHref: "/" + getAlternateLang(language) + "/live-events",
    linkHrefLang: getAlternateLang(language),
  };

  const onChangeTherapeuticalAreas = (values) => {
    setSeeHowMany(values.length > 0 ? 6 : 3);
    setActiveSlugs(values);
  };

  const onLoadMore = () => {
    setSeeHowMany((previousValue) => previousValue + 3);
  };

  useEffect(() => {
    window.dataLayer?.push({
      event: "page_info",
      page_language: language,
      page_category: "Live_Events",
      page_ta: "",
      page_type: "",
    });
    gtmPushUserInfo();
  }, [language]);

  return (
    <Layout seo={seo}>
      <ListDropdown onChange={onChangeTherapeuticalAreas} />
      <ListOfCards
        title={t("ourEvents")}
        cards={filteredLiveEvents.slice(0, seeHowMany).map((liveEvent) => (
          <CardLink
            title={liveEvent.node.Title}
            type="event"
            image={
              <GatsbyImage
                image={
                  liveEvent.node.thumbnail.localFile.childImageSharp
                    .gatsbyImageData
                }
                alt={
                  liveEvent.node.thumbnail.alternativeText
                    ? liveEvent.node.thumbnail.alternativeText
                    : ""
                }
              />
            }
            date={liveEvent.node.date}
            labelText={t("event")}
            labelColor={theme.palette.servier.purple2}
            link={`/live-events/${liveEvent.node.Slug}`}
            therapeuticAreaLabel={liveEvent.node.therapeutical_area.Name}
            therapeuticAreaColor={liveEvent.node.therapeutical_area.color}
          />
        ))}
        button={
          filteredLiveEvents.length > seeHowMany ? (
            <CustomButtonText
              text={t("loadMore")}
              onClick={onLoadMore}
              style={{
                letterSpacing: "0.1em",
              }}
            />
          ) : (
            <></>
          )
        }
        backgroundColor="#FFFFFF"
        style={{ marginTop: 20, paddingLeft: 0, paddingRight: 0 }}
        disableCarousel={isFiltered}
      />
      {newsletter && (
        <BrickNewsletter
          title={newsletter.Newsletter.title}
          subtitle={newsletter.Newsletter.subtitle}
          buttonText={newsletter.Newsletter.buttonText}
          backgroundImageData={
            newsletter.backgroundImage[0].localFile.childImageSharp
              .gatsbyImageData
          }
          alternativeText={newsletter.Newsletter.background.alternativeText}
          style={{ padding: 0 }}
        />
      )}
    </Layout>
  );
};

const ListLiveEvents = LiveEvents;
// const ListLiveEvents = ({ data }) => {
//   return <PrivateRoute component={LiveEvents} data={data} />;
// };
export default ListLiveEvents;
